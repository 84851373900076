import React, { useState } from 'react';
import './App.css';
import Login from './Components/Login';
import Dashboard from './Components/Dashboard';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  function handleLogin() {
    // logic for handling successful login
    setIsLoggedIn(true);
  }

  return (
    <div>
      {isLoggedIn ? <Dashboard /> : <Login onLogin={handleLogin} />}
    </div>
  );
}

export default App;

