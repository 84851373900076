import React, { useState, useRef } from 'react';
import './Login.css';

function Login({ onLogin }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const usernameRef = useRef();
  const passwordRef = useRef();

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Check if input fields are empty
    if (!username || !password) {
      setError("Please fill in all fields");
      if (!username) {
        usernameRef.current.focus();
      } else {
        passwordRef.current.focus();
      }
      setTimeout(() => {
        setError("");
      }, 2000);
      return;
    }

    // Make API call to verify login credentials
    fetch("login.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      body: `username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}`
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Network response was not ok.');
        }
      })
      .then((data) => {
        if (data.success) {
          onLogin();
        } else {
          // Display error message
          setError("Invalid username or password");
          if (!username) {
            usernameRef.current.focus();
          } else {
            passwordRef.current.focus();
          }
          setTimeout(() => {
            setError("");
          }, 2000);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }
  
  return (
    <div className="container-form">
      <form onSubmit={handleSubmit}>
        <label>
          Username:
          <input type="text" value={username} onChange={handleUsernameChange} ref={usernameRef} />
          {error && !username && <div className="error">{error}</div>}
        </label>
        <br />
        <label>
          Password:
          <input type="password" value={password} onChange={handlePasswordChange} ref={passwordRef} />
          {error && !password && <div className="error">{error}</div>}
        </label>
        <br />
        <button type="submit">Login</button>
        {error === "Invalid username or password" ? <div className="error">{error}</div> : ""}
      </form>
    </div>
  );
}

export default Login;
