import React, { useState, useEffect, useRef } from "react";
import "./Dashboard.css";

function Dashboard() {
  const [products, setProducts] = useState([]);
  const [editingId, setEditingId] = useState(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [code, setCode] = useState("");
  const [dimensions, setDimensions] = useState("");
  const [price, setPrice] = useState("");
  const [stock, setStock] = useState("");
  const [category, setCategory] = useState("");
  const [images, setImages] = useState([]);
  const [newProduct, setNewProduct] = useState({});
  const formRef = useRef(null);

  useEffect(() => {
    fetch("products.php")
      .then((response) => response.json())
      .then((data) => setProducts(data))
      .catch((error) => console.error(error));
  }, []);

  function handleEdit(id) {
    const productToEdit = products.find((product) => product.id === id);
    setEditingId(id);
    setTitle(productToEdit.title);
    setDescription(productToEdit.description);
    setCode(productToEdit.code);
    setDimensions(productToEdit.dimensions);
    setStock(productToEdit.stock);
    setPrice(productToEdit.price);
    setCategory(productToEdit.category);
  }

  function handleCancelEdit() {
    setEditingId(null);
    setTitle("");
    setDescription("");
    setCode("");
    setDimensions("");
    setStock("");
    setPrice("");
    setCategory("");
  }

  function handleSave(id) {
    // Check if all fields are completed
    if (
      !title ||
      !description ||
      !code ||
      !dimensions ||
      !stock ||
      !price ||
      !category
    ) {
      alert("Please complete all fields.");
      return;
    }

    if (!/^\d+(\.\d{1,2})?$/.test(price)) {
      alert("Please enter a valid price (e.g. 12.99).");
      return;
    }

    if (!/^\d+$/.test(stock)) {
      alert("Please enter a valid stock quantity (e.g. 10).");
      return;
    }

    const updatedProduct = products.find((product) => product.id === id);

    if (!updatedProduct) {
      return;
    }

    // Update all fields of the product
    updatedProduct.title = title;
    updatedProduct.description = description;
    updatedProduct.code = code;
    updatedProduct.dimensions = dimensions;
    updatedProduct.stock = stock;
    updatedProduct.price = price;
    updatedProduct.category = category;

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(updatedProduct),
    };

    fetch("update.php", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        const updatedProducts = products.map((product) => {
          if (product.id === data.id) {
            return data;
          }
          return product;
        });
        setProducts(updatedProducts);
        alert("Product updated successfully");
      })
      .catch((error) => console.error(error));

    setEditingId(null);
    setTitle("");
    setDescription("");
    setCode("");
    setDimensions("");
    setStock("");
    setPrice("");
    setCategory("");
  }

  function handleDeleteProduct(id) {
    const requestOptions = {
      method: "DELETE",
    };

    fetch(`deleteproduct.php?id=${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log("Data returned from server:", data);
        const updatedProducts = products.filter((product) => product.id !== id);
        setProducts(updatedProducts);
      })
      .catch((error) => console.error(error));
  }

  console.log(products);
  console.log(images);

  function handleAddProduct(e) {
    e.preventDefault();

    // verify that all form fields are completed and have correct format
    if (formRef.current) {
      const formInputs = formRef.current.querySelectorAll(
        "input, textarea, select"
      );
      for (let i = 0; i < formInputs.length; i++) {
        if (!formInputs[i].value) {
          alert("Please fill in all required fields.");
          return;
        }

        // check if stock and price inputs are numeric
        if (
          (formInputs[i].name === "stock" || formInputs[i].name === "price") &&
          !/^\d+(\.\d+)?$/.test(formInputs[i].value)
        ) {
          alert("Stock and Price should accept only numeric values.");
          return;
        }
      }
    }

    /*const formData = new FormData();
    const imagesArray = Object.values(images);
    imagesArray.forEach((image) => {
      formData.append("images[]", image);
    });

    // Sanitize the newProduct object by removing empty values and extra commas/quotation marks
    const sanitizedProduct = {};
    for (const key in newProduct) {
      if (newProduct.hasOwnProperty(key) && newProduct[key]) {
        sanitizedProduct[key] = newProduct[key].replace(/[,']/g, "");
      }
    }

    formData.append("productData", JSON.stringify(sanitizedProduct));
    const requestOptions = {
      method: "POST",
      body: formData,
    };*/
    //console.log("fetch",requestOptions);
    const formData = new FormData();
    const imagesArray = Object.values(images);
    imagesArray.forEach((image) => {
      formData.append("images[]", image);
    });

    formData.append("productData", JSON.stringify(newProduct));
    const requestOptions = {
      method: "POST",
      body: formData,
    };

    fetch("newproduct.php", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setProducts([...products, data]);
        setNewProduct({});
        setImages([]);
        alert("Product succesfully added");
      })
      .catch((error) => console.log(error));
    if (formRef.current) {
      formRef.current.reset();
    }
  }

  return (
    <>
      <div class="container-p-list">
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Title</th>
              <th>Description</th>
              <th>Code</th>
              <th>Dimensions</th>
              <th>Stock</th>
              <th>Price</th>
              <th>Category</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {products.map((product) => (
              <tr key={product.id}>
                <td>{product.id}</td>
                <td class="label-dash-list">
                  {editingId === product.id ? (
                    <input
                      type="text"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  ) : (
                    product.title
                  )}
                </td>
                <td class="label-dash-list">
                  {editingId === product.id ? (
                    <textarea
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  ) : (
                    product.description
                  )}
                </td>
                <td class="label-dash-list">
                  {editingId === product.id ? (
                    <input
                      value={code}
                      onChange={(e) => setCode(e.target.value)}
                    />
                  ) : (
                    product.code
                  )}
                </td>
                <td class="label-dash-list">
                  {editingId === product.id ? (
                    <input
                      value={dimensions}
                      onChange={(e) => setDimensions(e.target.value)}
                    />
                  ) : (
                    product.dimensions
                  )}
                </td>
                <td class="label-dash-list">
                  {editingId === product.id ? (
                    <input
                      type="text"
                      value={stock}
                      onChange={(e) => setStock(e.target.value)}
                    />
                  ) : (
                    product.stock
                  )}
                </td>
                <td class="label-dash-list">
                  {editingId === product.id ? (
                    <input
                      type="text"
                      value={price}
                      onChange={(e) => setPrice(e.target.value)}
                    />
                  ) : (
                    product.price
                  )}
                </td>
                <td class="label-dash-list">
                  {editingId === product.id ? (
                    <input
                      type="text"
                      value={category}
                      onChange={(e) => setCategory(e.target.value)}
                    />
                  ) : (
                    product.category
                  )}
                </td>
                <td class="label-dash-list">
                  {editingId === product.id ? (
                    <>
                      <button
                        class="btn-dash spc"
                        onClick={() => handleSave(product.id)}
                      >
                        Save
                      </button>
                      <button
                        class="btn-dash spc"
                        onClick={() => handleCancelEdit()}
                      >
                        Cancel
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        class="btn-dash"
                        onClick={() => handleEdit(product.id)}
                      >
                        Edit
                      </button>
                      <button
                        class="btn-dash"
                        onClick={() => {
                          const confirmDelete = window.confirm(
                            "Lucian, sigur vrei sa stergi acest produs?"
                          );
                          if (confirmDelete) {
                            handleDeleteProduct(product.id);
                          }
                        }}
                      >
                        Delete
                      </button>
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div class="container-newproduct">
        <div class="title-form">Add new product</div>
        <form
          class="form-dash"
          ref={formRef}
          onSubmit={(e) => handleAddProduct(e, images)}
        >
          <div class="column">
            <label class="label-dash">
              Title:
              <input
                type="text"
                onChange={(e) =>
                  setNewProduct({ ...newProduct, title: e.target.value })
                }
              />
            </label>
            <label class="label-dash">
              Description:
              <textarea
                onChange={(e) =>
                  setNewProduct({ ...newProduct, description: e.target.value })
                }
              />
            </label>
            <label class="label-dash">
              Code:
              <input
                type="text"
                onChange={(e) =>
                  setNewProduct({ ...newProduct, code: e.target.value })
                }
              />
            </label>
            <label class="label-dash">
              Dimensions:
              <input
                type="text"
                onChange={(e) =>
                  setNewProduct({ ...newProduct, dimensions: e.target.value })
                }
              />
            </label>
          </div>
          <div class="column">
            <label class="label-dash">
              Stock:
              <input
                type="text"
                name="stock"
                onChange={(e) =>
                  setNewProduct({ ...newProduct, stock: e.target.value })
                }
              />
            </label>
            <label class="label-dash">
              Price:
              <input
                type="text"
                name="price"
                onChange={(e) =>
                  setNewProduct({ ...newProduct, price: e.target.value })
                }
              />
            </label>
            <label class="label-dash">
              Category:
              <input
                type="text"
                onChange={(e) =>
                  setNewProduct({ ...newProduct, category: e.target.value })
                }
              />
            </label>
            <label class="label-dash" htmlFor="imageFile">
              Image:
              <input
                type="file"
                id="imageFile"
                multiple
                onChange={(e) => setImages(e.target.files)}
              />
            </label>
          </div>
          <button class="btn-add-dash" type="submit">
            Add Product
          </button>
        </form>
      </div>
    </>
  );
}

export default Dashboard;
